import { Pipe, PipeTransform } from '@angular/core';
import { cashTypes } from '../lists/cash-types';

@Pipe({
  name: 'cashType'
})
export class CashTypePipe implements PipeTransform {

  transform(value: number, args?: any): string {
    for (let type of Object.keys(cashTypes)) {
      if (cashTypes[type].id == value) {
        return cashTypes[type].label;
      }
    }
    return '';
  }

}
