import { Pipe, PipeTransform } from '@angular/core';
import { Operation } from '../../interface/operation';

@Pipe({
  name: 'operationFilter'
})
export class OperationFilterPipe implements PipeTransform {

  transform(array: any[], searchString: string): any {
    if (!array || !searchString) {
      return []
    }

    const filtered = array.filter((operation: Operation) => {
      if ((operation.description)
        .toLowerCase()
        .includes(searchString.toLowerCase())) {
        return true
      }
      if ((operation.expenseType && operation.expenseType.description.toLowerCase().includes(searchString.toLowerCase()))) {
        return true
      }
      if (operation.ICMS && operation.ICMS.CFOP && operation.ICMS.CFOP.toLowerCase().includes(searchString.toLowerCase())){
        return true
      }
      return false;
    })
    return filtered

  }

}
