import moment from "moment";
import { toASCII, toUnicode } from "punycode";
import { Supplier } from "../interface/supplier";
import { AddressUtilities } from "./address-utilities";
import { Utilities } from "./utilities";

export class SupplierUtilities extends Utilities {

  static changed(a: Supplier, b: Supplier): boolean {
    return !this.equalString(a.name, b.name)
      || !this.equalUnmasked(a.document, b.document)
      || !this.equalDate(a.birthday, b.birthday)
      || a.lang != b.lang || a.gender != b.gender
      || a.maritalStatus != b.maritalStatus
      || !this.equalUnmasked(a.phone1, b.phone1)
      || !this.equalUnmasked(a.phone2, b.phone2)
      || !this.equalString(a.email, b.email)
      || AddressUtilities.changed(a.address, b.address)
      || a.cardAdmin.length != b.cardAdmin.length
  }

  static complyApp(supplier: Supplier): Supplier {
    supplier.phone2 = this.removeMask(supplier.phone2);
    supplier.email = toASCII((supplier.email || '').trim());
    supplier.address = supplier.address || {};
    
    if(supplier.birthday){
      // fix incorrect timezone for birthday
      const birth = moment(supplier.birthday.slice(0, 10));
      if(birth.valueOf()){
        supplier.birthday = birth.toISOString();
      }
    }
    
    return supplier;
  }

  static complyApi(appSupplier: Supplier): Supplier {
    let supplier = {...appSupplier};
    delete supplier.photoBlob;
    delete supplier.photoInput;
    delete supplier.address.stateName;
    delete supplier.address.cityName;
    if (!Number.isFinite(supplier.creditLimit)) { delete supplier.creditLimit }
    
    if (!supplier.address.cep) { delete supplier.address.cep }
    if (!supplier.address.city) { delete supplier.address.city }
    if (!supplier.address.complement) { delete supplier.address.complement }
    if (!supplier.address.neighborhood) { delete supplier.address.neighborhood }
    if (!supplier.address.number) { delete supplier.address.number }
    if (!supplier.address.state) { delete supplier.address.state }
    if (!supplier.address.street) { delete supplier.address.street }
    if (!Object.keys(supplier.address).length) {
      delete supplier.address
    }

    supplier.document = (supplier.document || "").replace(/[\W]/gm, '');
    supplier.phone1 = this.removeMask(supplier.phone1) || ' ';
    supplier.phone2 = this.removeMask(supplier.phone2) || ' ';
    supplier.email = supplier.email ? toUnicode(supplier.email) : null;
    if(!supplier.email){
      delete supplier.email
    }
    if(!supplier.phone2){
      delete supplier.phone2
    }
    return supplier;
  }

}
