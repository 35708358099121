import { Pipe, PipeTransform } from '@angular/core';
import { RoFull } from '../../interface/ro-full';

@Pipe({
  name: 'osTab'
})
export class OsTabPipe implements PipeTransform {

  transform(tabName: string, os?: RoFull): string {
    let resp = ""
    switch (tabName) {
      case "ro_part":
        if (os && os.parts && os.parts.length) {
          resp += ` (${os.parts.length})`;
        }
        break;
      case "ro_labor":
        if (os && os.labors && os.labors.length) {
          resp += ` (${os.labors.length})`;
        }
        break;
      case "ro_outsourced":
        if (os && os.thirdPartyServices && os.thirdPartyServices.length) {
          resp += ` (${os.thirdPartyServices.length})`;
        }
        break;
      default:
        break;
    }

    return resp;
  }

}
