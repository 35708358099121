import { itemCheck } from "../shared/lists/checklist-item-check";
import { Checklist } from "./checklist";

interface APIchecklistItem {
    readonly _id?: string,
    readonly __v?: number,
    readonly available?: number,
    readonly company?: string,
    readonly createdAt?: string,
    readonly updatedAt?: string,
    checklistModel?: string,
    observation?: string,
    answers?: Answer[],
    url?: string;
    combustibleLvl?: number,
    os?: string,
    user?: string,
    client?: string,
    vehicle?: string,
    prisma?: string,
    urlSignature?: string,
    km?: number | string;
    images?: string[];
    final?: CHECKLIST_MODEL_ITEM_STATE;

    /** @ignore */
    checklist?: Array<any>,
    /** @ignore */
    defaultText?: string,
}

export enum QUESTION_TYPE {
    "LIST" = 1,
    "BOOLEAN",
    "DAMAGE_LEVEL",
    "NUMERIC_NIVEL",
    "TEXT",
    "STAR" // 0 to 5 stars
};

export enum CHECKLIST_MODEL_ITEM_STATE {
    "OPEN" = 1,
    "CLOSED" = 2
}

export interface Question {
    _id?: string;
    questionType: QUESTION_TYPE;
    /**
     * @todo
     *
     * dois atributos não são necessários,
     * @API mudar apara apenas questionName
     * */
    booleanQuestionName: string;
    // listQuestionName: string; // unused
    list?: Array<string>;

    group: string;

    /** The min value of an NUMERIC_NIVEL response */
    min?: number;
    /** The max value of an NUMERIC_NIVEL response */
    max?: number;
    /** The defalt value of an NUMERIC_NIVEL response */
    default?: number;
    /** The step value for slider response of an NUMERIC_NIVEL response */
    step?: number;
    /** The min range for red color of an NUMERIC_NIVEL response */
    rangeMinRed?: number;
    /** The max range for red color of an NUMERIC_NIVEL response */
    rangeMaxRed?: number;
    /** The min range for yellow color of an NUMERIC_NIVEL response */
    rangeMinYellow?: number;
    /** The max range for yellow color of an NUMERIC_NIVEL response */
    rangeMaxYellow?: number;
    /** The min range for green color of an NUMERIC_NIVEL response */
    rangeMinGreen?: number;
    /** The max range for green color of an NUMERIC_NIVEL response */
    rangeMaxGreen?: number;

    /** local only */
    _temp_response?: any;

}

export interface Answer {
    readonly _id?: string,
    /** id de checklistModel */
    question: string;
    questionType: QUESTION_TYPE;
    booleanAnswer?: boolean;
    listAnswer?: Array<string | number | boolean>;
    readonly createdAt?: string,
    readonly updatedAt?: string
    /** Local only */
    _questionName?: string;
}


export class ChecklistModel {
    /** id of Checklist Model */
    _id: string;

    /** URl to blueprint image for breakpoints */
    url: string;
    /** vehicle model */
    model: string;
    /** The vehicle brand */
    group: string;
    /** A predifined text to be show into PDF */
    defaultText: string;
    questions: Array<Question>;
    checklist: Array<Checklist>;

    /** for questionType Nivel: */
    step?: number;

    constructor(args: any) {
        if (args) {
            this.model = args.model;
            this.group = args.group;
            this.defaultText = args.defaultText;
            this.checklist = args.chchecklist || [];
            this.questions = args.questions ? args.questions.slice().map(q => {
                const question = {
                    "_id": q._id,
                    "questionType": q.questionType,
                    "booleanQuestionName": q.booleanQuestionName,
                    "group": q.group,
                };
                switch (q.questionType) {
                    case QUESTION_TYPE.LIST:
                        Object.assign(question, { list: q.list });
                        break;
                    case QUESTION_TYPE.BOOLEAN:
                        Object.assign(question, { _temp_response: true });
                        break;
                    case QUESTION_TYPE.NUMERIC_NIVEL:
                        Object.assign(question, {
                            "min": q.min,
                            "max": q.max,
                            "default": q.default,
                            "step": q.step,
                            "rangeMinRed": q.rangeMinRed,
                            "rangeMinYellow": q.rangeMinYellow,
                            "rangeMinGreen": q.rangeMinGreen,
                            "rangeMaxRed": q.rangeMaxRed,
                            "rangeMaxYellow": q.rangeMaxYellow,
                            "rangeMaxGreen": q.rangeMaxGreen,
                        })
                    default:
                        break;
                }
                return question;
            }) : [];
            this.url = args.url;
            if (args._id) {
                this._id = args._id;
            }
        }
    }

    public generateItem(): ChecklistItem {
        const answers: Answer[] = [];
        for (const question of this.questions) {
            let defaultListAnswer;
            switch (question.questionType) {
                case QUESTION_TYPE.DAMAGE_LEVEL:
                    defaultListAnswer = [itemCheck.not_Applied];
                    break;
                case QUESTION_TYPE.NUMERIC_NIVEL:
                    defaultListAnswer = question.default || 0;
                    break;
                default:
                    defaultListAnswer = [];
                    break;
            }
            answers.push({
                _questionName: question.booleanQuestionName,
                question: question._id,
                questionType: question.questionType,
                booleanAnswer: false,
                listAnswer: defaultListAnswer
            });
        }

        const chItem = new ChecklistItem({
            observation: this.defaultText,
            combustibleLvl: 0,
            answers
        });
        return chItem;
    }

}

export class ChecklistItem {
    _id: string;

    readonly createdAt: string;

    /** id of ChecklistModel used to create this checklist item */
    checklistModel: string;

    /** breakdowns image url */
    url?: string;

    /** URL to image of client signature */
    urlSignature?: string;
    os: string;
    user: string;
    answers?: Array<Answer>;
    client: string;
    km: number;
    vehicle: string;

    observation: string;
    combustibleLvl: number;

    /** vehcile localization */
    prisma: string;

    /** Item photos URLs*/
    images: string[];
    /** indicate if checklist is finished and can be edited */
    final: CHECKLIST_MODEL_ITEM_STATE;

    private _checklistModelInstance: ChecklistModel;

    constructor(args: APIchecklistItem) {
        Object.assign(this, args)
        if (!this.images) {
            this.images = [];
        }
        if(!this.final){
            this.final = CHECKLIST_MODEL_ITEM_STATE.OPEN;
        }
    }

    toAPI() {
        const apiObj: APIchecklistItem = {
            "checklistModel": this.checklistModel,
            "combustibleLvl": this.combustibleLvl,
            "observation": this.observation,
            "url": this.url,
            "urlSignature": this.urlSignature,
            "os": this.os,
            "answers": this.answers.slice().map(ans => {
                delete ans._questionName;
                return ans
            }),
            "user": this.user,
            "client": this.client,
            "vehicle": this.vehicle,
            "prisma": this.prisma,
            "km": this.km,
            "images": this.images,
            "final": this.final || CHECKLIST_MODEL_ITEM_STATE.OPEN
        }
        return apiObj;
    }

    public set _modelInstance(model: ChecklistModel) {
        this.checklistModel = model._id;
        this._checklistModelInstance = model;
    }

    public get _modelInstance() {
        return this._checklistModelInstance;
    }

    public getGroupedQuestions(): { groupName: string, questions: Question[] }[] {
        if (!this._modelInstance || !this._modelInstance.questions || !this._modelInstance.questions.length) {
            return [];
        }
        const groupMap = new Map<string, Question[]>();
        for (const question of this._modelInstance.questions) {
            const grouped = groupMap.get(question.group) || [];
            if (this._id) {
                // retrieve answer and set to _temp_response
                const answer = this.answers.find(ans => ans.question === question._id);
                if (answer) {
                    switch (question.questionType) {
                        case QUESTION_TYPE.BOOLEAN:
                            question._temp_response = answer.booleanAnswer === false ? false : true;
                            break;
                        case QUESTION_TYPE.LIST:
                        case QUESTION_TYPE.TEXT:
                            // prevent errors casting undefined value as string
                            question._temp_response = (answer.listAnswer[0] || "");
                            break;
                        default:
                            question._temp_response = answer.listAnswer[0];
                            break;
                    }
                }
            }
            grouped.push(question);
            groupMap.set(question.group, grouped);
        }
        const resp: { groupName: string, questions: Question[] }[] = [];
        for (const key of groupMap.keys()) {
            const questions = groupMap.get(key);
            resp.push({
                groupName: key,
                questions
            });
        }
        return resp;
    }

}
