import { Pipe, PipeTransform } from '@angular/core';

/**
 *  Used to get a view value of a input containing a mat-autocomplete 
 * 
 * @see https://github.com/angular/components/issues/4863
 * 
 */
@Pipe({
  name: 'displayWith'
})
export class DisplayWithPipe implements PipeTransform {

  /**
   * 
   * @param arr The array with all possible values for the autocomplete
   * @param valueProperty The property selected as value of input
   * @param viewValueProperty The property selected as viewValue of mat-option
   * @returns a function to display the value
   */
  transform(arr: Array<any>, valueProperty: string, viewValueProperty: string): (inputValue) => string {
    return (value: string) => {
      if (arr) {
        const found = arr.find(item => item[valueProperty] == value);
        if (found) {
          return found[viewValueProperty] as string;
        }
      }
      return "";
    }
  }

}
