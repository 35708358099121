import { LabelType, Options } from '@angular-slider/ngx-slider';
import { Pipe, PipeTransform } from '@angular/core';
import { CHECKLIST_MODEL_ITEM_STATE, ChecklistItem, Question } from '../../interface/checklist-model';
import { SurveyItem } from '../../interface/survey-item';


@Pipe({
  name: 'sliderConfig'
})
export class SliderConfigPipe implements PipeTransform {

  transform(question: Question, existentItem?: ChecklistItem|SurveyItem): Partial<Options> {
    // @see https://angular-slider.github.io/ngx-slider/docs
    let disabled: boolean = false;
    
    if(existentItem instanceof ChecklistItem) {
      disabled = existentItem.final === CHECKLIST_MODEL_ITEM_STATE.CLOSED;
    }

    return {
      floor: question.min,
      ceil: question.max,
      step: question.step || 1,
      showSelectionBar: true,
      showTicks: true,
      showTicksValues: false,
      disabled,
      getSelectionBarColor: (min: number, max?: number) => {
        if(Number.isFinite(question.rangeMaxRed) && min < question.rangeMaxRed){
          return 'red';
        }
        if(Number.isFinite(question.rangeMaxYellow) && min < question.rangeMaxYellow){
          return 'yellow';
        }
        return 'green';
      },
      translate: (value, label) => {
        if (existentItem && existentItem._id) {
          switch (label) {
            case LabelType.Floor:
            case LabelType.Ceil:
              return "";
            default:
              return `${value}`;
          }
        }
        return `${value}`;
      }
    };
  }

}
