import { Pipe, PipeTransform } from '@angular/core';
import { CFOP } from '../../interface/cfop';

@Pipe({
  name: 'cfop'
})
export class CfopPipe implements PipeTransform {

  transform(cfopList: CFOP[], text = ''): CFOP[] {
    return cfopList.filter(cfop =>
      cfop.message.toLowerCase().includes(text) || cfop.code.toLowerCase().includes(text)
    );
  }

}
