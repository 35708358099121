import { Pipe, PipeTransform } from '@angular/core';
// import { NF_response } from 'src/app/interface/NF-response';
import { INVOICE_STATE } from '../lists/invoice-state';

@Pipe({
  name: 'invoiceStatus'
})
export class InvoiceStatusPipe implements PipeTransform {

  transform(state: number = INVOICE_STATE.PENDING) {
    const txt = INVOICE_STATE[state] ;
    return txt || "PENDING"
  }

}
