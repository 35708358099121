import { Pipe, PipeTransform } from '@angular/core';
import { toUnicode } from 'punycode';

@Pipe({
  name: 'email'
})
export class EmailPipe implements PipeTransform {

  transform(value: string, args?: any): string {
    if (!value) {
      return '';
    }
    return toUnicode(value);
  }

}
