import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phone'
})
export class PhonePipe implements PipeTransform {

  transform(value: string | number): string {
    if(!value) {
      return ""
    }
    let formated = (value.toString().replace(/\D/g, ''));

    switch (formated.length) {
      case 8: // 0000-0000
        return formated.replace(/(\d{4})(\d{4})/g, "\$1-\$2");
      case 10: // (00) 0000-0000
        return formated.replace(/(\d{2})(\d{4})(\d{4})/g, "(\$1) \$2-\$3");
      case 11: // (00) 90000-0000
        return formated.replace(/(\d{2})(\d{5})(\d{4})/g, "(\$1) \$2-\$3");
      case 12: // +1(00) 90000-0000
        return formated.replace(/(\d{1})(\d{2})(\d{5})(\d{4})/g, "+\$1(\$2) \$3-\$4");
      case 13: // +12(00) 90000-0000
        return formated.replace(/(\d{2})(\d{2})(\d{5})(\d{4})/g, "+\$1(\$2) \$3-\$4");
      case 14: // +123(00) 90000-0000
        return formated.replace(/(\d{3})(\d{2})(\d{5})(\d{4})/g, "+\$1(\$2) \$3-\$4");
      default:
        return formated;
    }
  }
}
