import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'deadline'
})
export class DeadlinePipe implements PipeTransform {

  transform(value: Date, min: Date, max: Date): "past" | "future" | "today" | null {
    if(!value || !min || !max){
      return null;
    }
    const valueTime = value.getTime();
    const minTime = min.getTime();
    if(valueTime < minTime){
      return "past";
    }
    const maxTime = max.getTime();
    if(valueTime > maxTime){
      return "future";
    }
    return "today";
  }

}
