import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cep'
})
export class CepPipe implements PipeTransform {

  transform(value: string): any {
    
    if (!value) {
      return "";
    }
    let unmasked = (value.toString().replace(/\D/g, ''));
    return unmasked.replace(/(\d{2})(\d{3})(\d{3})/g, "\$1.\$2\-\$3");
  }

}
