import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { Utilities } from '../../class/utilities';

@Pipe({
  name: 'kilometers'
})
export class KilometersPipe extends DecimalPipe implements PipeTransform {

  override transform(value: string | number, args?: any): any {
    let language = Utilities.getLanguage();
    if (language == 'es') {
      language = 'es-MX';
    }
    
    if(typeof value === 'string') {
      if(!value.match(/\d/g)) {
        return value;
      }
      value = value.replace(/\D/g,'');
    }

    return super.transform(value, '1.0', language).concat(" KM");
  }

}
