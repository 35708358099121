import { Pipe, PipeTransform } from '@angular/core';
import { SideMenu } from '../../interface/side-menu';

@Pipe({
  name: 'menuSelected'
})
export class MenuSelectedPipe implements PipeTransform {

  transform(layoutComponent: string, menu: SideMenu, sidenav: boolean, expanded: boolean): boolean {
    if(!layoutComponent || !menu){
      return false
    }
    if (layoutComponent === menu.component){
      return true;
    }
    if(menu.shadows && menu.shadows.length && menu.shadows.includes(layoutComponent)){
      return true
    }
    if (!expanded){
      if (menu.childs && menu.childs.length){
          if (menu.childs.some(child => child.component === layoutComponent)){
            return true;
          }
          if(menu.childs.some(child => child.shadows && child.shadows.length && child.shadows.includes(layoutComponent))){
            return true;
          }
      }
    }
    return false
  }

}
