import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'reduceSum'
})
export class ReduceSumPipe implements PipeTransform {

  transform(array: Array<any>, property?: string, initialValue = 0) {
    return array.reduce((acc, item) => {
      if(property){
        return acc + (item[property] || 0);
      }
      return acc + item;
    }, initialValue)
  }

}
