import { Pipe, PipeTransform } from '@angular/core';
import { DOCUMENT_TYPE } from '../lists/document-type';

@Pipe({
  name: 'documentType'
})
export class DocumentTypePipe implements PipeTransform {

  transform(value: number): any {
    const resp = DOCUMENT_TYPE.find(_type => _type.code === value);
    return resp ? resp.description : '';
  }

}
