import { Pipe, PipeTransform } from '@angular/core';
import { NF_response } from '../../interface/NF-response';
import { NFSE_response, ResponseRPS } from '../../interface/NFSE-response';
import { Company } from '../../interface/company';
import { NFSe } from '../../interface/NFS-e';
import { Utilities } from '../../class/utilities';
import { NFCe } from '../../interface/NFC-e';
import { NFe } from '../../interface/NF-e';

@Pipe({
  name: 'invoiceProperty'
})
export class InvoicePropertyPipe implements PipeTransform {

  /**
   * 
   * @param invoice 
   * @param property One of these
   *  * model
   *  * dhEmit
   *  * cnpj - The client/supplier cnpj of invoice
   *  * serie
   *  * number
   *  * value
   *  * client - require cnpj
   *  * clientDocument - only for NFe/NFCe
   *  * pdfStr
   *  * pdfLink
   *  * xmlLink
   *  * inOut - require cnpj
   *  * NFRef - only for NFe/NFCe
   *  * additionalInfo - only for NFe/NFCe
   *  * rejection
   *  * laborsValue - only for NFSe
   *  * discountValue
   *  * freigthValue
   *  * partsValue
   *  * observation
   * @param company Required if you want the property "isOut" or "client"
   * @returns 
   */

  public downloadPdf(invoice: NF_response | NFSE_response) {
    const str64 = this.transform(invoice, 'pdfStr');
    const filename = `${invoice.request.Documento.ModeloDocumento}-${invoice.id}.pdf`;
    if (str64) {
      this.getPDFbyBytes(str64, filename);
    } else {
      console.warn("Não foi encontrada a string Base64 da nota, abrindo PDF via link")
      const link = new InvoicePropertyPipe().transform(invoice, 'pdfLink');
      if (!link) {
        return;
      }
      let a = document.createElement('a');
      a.href = link;
      a.target = "_blank";
      a.download = filename;
      a.click();
    }
  }

  private getPDFbyBytes(str: string, filename: string) {
    let a = document.createElement("a");
    a.href = "data:application/octet-stream;base64," + str;
    a.download = filename;
    a.click();
  }


  transform(invoice: (NF_response | NFSE_response), property: string, company?: Company): any {

    if (!invoice) {
      return '';
    }

    const isNFSE = (invoice && invoice.request && invoice.request.Documento && invoice.request.Documento.ModeloDocumento === 'NFSE');

    if (isNFSE) {
      const nfse = invoice.request.Documento as NFSe;
      if (!nfse.RPS.length) {
        // avoid access an property of undefined value
        return undefined;
      }
      let response: ResponseRPS;
      if (invoice.response) {
        if (invoice.response && (invoice.response as any).Documentos && (invoice.response as any).Documentos.length) {
          response = (invoice as any).response.Documentos[0];
        } else {
          response = (invoice as any).response.Situacao;
        }
      }

      const cnpj = nfse.RPS[0].Prestador.CNPJ_prest || (nfse.RPS[0].Prestador as any).CNPJ;
      const isOut = (company ? Utilities.removeMask(cnpj) === Utilities.removeMask(company.cnpj) : true);
      switch (property) {
        case 'model': return nfse.ModeloDocumento;
        case 'dhEmit': return nfse.RPS[0].dEmis;
        case 'cnpj': return cnpj;
        case 'serie': return nfse.RPS[0].RPSSerie;
        case 'number': return nfse.RPS[0].RPSNumero;
        case 'value': return Number(nfse.RPS[0].Servico.Valores.ValLiquido);
        case 'client':
          return isOut ? (nfse.RPS[0].Tomador ? nfse.RPS[0].Tomador.TomaRazaoSocial : '') : nfse.RPS[0].Prestador.xNome;
        case 'pdfStr': return response.DocPDFBase64 ?? response['DocPDF'];
        case 'pdfLink':
          if (response) {
            if (response.DocImpPrefeitura) {
              return response.DocImpPrefeitura;
            }
            if (response.DocPDFDownload) {
              return response.DocPDFDownload;
            }
            if ((response as any).DocPDFLink) {
              return (response as any).DocPDFLink;
            }
          }
          return '';
        case 'xmlStr': return response ? (response.DocXMLBase64 || response['DocXML']) : "";
        case 'xmlLink':
          if (response) {
            return response.DocXMLDownload || (response as any).DocXMLLink;
          }
          return '';
        case 'inOut': return isOut ? 'Saída' : 'Entrada';
        case 'rejection':
          if (response) {
            if (response.Situacao) {
              return response.Situacao.SitDescricao;
            }
            return (response as any).DocSitDescricao || (response as any).Descricao;
          }
          return invoice.response ? (invoice.response as any).Descricao : '';
        case 'laborsValue':
          return Number(nfse.RPS[0].Servico.Valores.ValServicos) || 0;
        case 'discountValue':
          return (Number(nfse.RPS[0].Servico.Valores.ValDescCond) || 0) + (Number(nfse.RPS[0].Servico.Valores.ValDescIncond) || 0);
        case 'freigthValue':
        case 'partsValue':
          return 0;
        case 'observation': return nfse.RPS[0].NFSOutrasinformacoes;
        // case 'laborsValue':
        // return invoice.response.
        default: return invoice[property];
      }

    } else {
      const nf = invoice.request.Documento as NFCe | NFe;
      const ICMStot = nf.total ? (nf.total.ICMStot || (nf as any).total.ICMSTot) : undefined;
      const response = (invoice as NF_response).response ? (invoice as NF_response).response.Documentos : undefined;
      switch (property) {
        case 'model': return nf.ModeloDocumento || (nf.ide.mod === '55' ? 'NFe' : 'NFCe');
        case 'dhEmit': return nf.ide.dhEmi;
        case 'cnpj': return nf.emit.CNPJ_emit || (nf.emit as any).CNPJ || nf.emit.CPF_emit || (nf.emit as any).CPF || (nf.emit as any).idEstrangeiro;
        case 'serie': return (nf.ide.serie ?? '').toString(); // alguns clientes (malditos) utilizam série 0
        case 'number': return (nf.ide.nNF ?? '').toString();
        case 'value': return ICMStot ? Number(ICMStot.vNF) : undefined;
        case 'client':
          if (!nf.dest) {
            return 'Consumidor não identificado';
          }
          return nf.ide.tpNf === 1 ? (nf.dest.xNome_dest || (nf as any).dest.xNome) : nf.emit.xNome;
        case 'clientDocument':
          if (!nf.dest) {
            return "";
          }
          if (nf.ide.tpNf === 1) {
            if (!nf.dest) {
              return undefined
            }
            return (nf.dest.CNPJ_dest || (nf.dest as any).CNPJ || (nf.dest as any).CPF || nf.dest.CPF_dest);
          }
          return (nf.emit.CNPJ_emit || (nf.emit as any).CNPJ || nf.emit.CPF_emit || (nf.emit as any).CPF);
        case 'pdfStr':
          let respPdf = '';
          if (response && response.length) {
            respPdf = response[0].DocPDFBase64 || (response[0] as any).DocPDF;
          }
          return respPdf;
        case 'pdfLink':
          if (response && response.length) {
            return response[0].DocPDFDownload || (response[0] as any).DocPDFLink;
          }
          return '';
        case 'xmlStr':
          let respXml = '';
          if (response && response.length) {
            respXml = response[0].DocXMLBase64 || (response[0] as any).DocXML;
          }
          return respXml;
        case 'xmlLink':
          if (response && response.length) {
            return response[0].DocXMLDownload || (response[0] as any).DocXMLLink;
          }
          return '';
        case 'inOut': return (nf.ide.tpNf === 0 ? 'Entrada' : 'Saída');
        case 'NFRef':
          if (nf.ide && nf.ide.NFRef) {
            if (typeof nf.ide.NFRef === 'string') {
              return nf.ide.NFRef
            } else if (Array.isArray(nf.ide.NFRef)) {
              return (nf.ide.NFRef as any)[0].refNFe;
            } else {
              return (nf.ide.NFRef as any).refNFe;
            }
          }
          if (nf.ide && (nf.ide as any).accessKey) {
            return nf.ide && (nf.ide as any).accessKey;
          }
          return '';
        case 'additionalInfo': return (nf as NFe).infAdic ? (nf as NFe).infAdic.infCpl : '';
        case 'freigthValue': return Number((ICMStot ? (ICMStot.vFrete_ttlnfe || ICMStot.vFrete) : '0')) || 0;
        case 'partsValue': return Number((ICMStot ? (ICMStot.vProd_ttlnfe || ICMStot.vProd) : '0')) || 0;
        case 'discountValue': return Number(ICMStot ? (ICMStot.vDesc_ttlnfe || ICMStot.vDesc) : '0') || 0;
        case 'laborsValue': return 0;
        case 'rejection':
          if (response && response.length) {
            if (response[0].Situacao) {
              return (invoice as NF_response).response.Documentos[0].Situacao.SitDescricao;
            }
            return (invoice as any).response.Documentos[0].DocSitDescricao || '';
          }
          return (invoice.response as any).Descricao || '';
        case 'observation': return (nf as NFe).infAdic ? (nf as NFe).infAdic.infCpl : ''
        default: return invoice[property];
      }
    }
  }

}
