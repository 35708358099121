import { Pipe, PipeTransform } from '@angular/core';
import { detItem55 } from '../../interface/NF-e';
import { Part } from '../../interface/part';

@Pipe({
  name: 'invoicePartIncluded'
})
export class InvoicePartIncludedPipe implements PipeTransform {

  transform(selectedItems: detItem55[], part: Part): boolean {
    if(!part || !selectedItems || !selectedItems.length){
      return false
    }

    return selectedItems.some(item => item.prod && item.prod.cProd === part.code)
  }

}
