export enum PAYMENT_METHODS {
  "money" = '01',
  "check" = '02',

  /**
   * The PAYMENT in credit card should create the titles against the card admin.
   * 
   * The MekanicWEB sytem no accept RECEIVES in credit card.
   */
  "credit" = '03',

  /**
   * The PAYMENT in debit card should move bank balance.
   * 
   * The RECEIVES should create titles against the card admin.
   */
  "debit" = '04',
  "shopCard" = '05',
  "food" = '10',
  "fastFood" = '11',
  "gift" = '12',
  "fuel" = '13',
  "duplicate" = '14',
  "bankSlip" = '15',
  "deposit" = '16',
  "dynamicPix" = '17',
  "bankTransfer" = '18',
  "cashback" = '19',
  "staticPix" = '20',
  "shopCredit" = '21',
  "notInformed" = '22',
  "noPayment" = '90', // used for devolution
  "other" = '99'
}

export type PAYMENT_METHOD = keyof typeof PAYMENT_METHODS;

export const PaymentMethodsKeys = Object.keys(PAYMENT_METHODS) as PAYMENT_METHOD[];
