import { Pipe, PipeTransform } from '@angular/core';
import { City } from '../../interface/city';
import { State } from '../../interface/state';

@Pipe({
  name: 'address'
})
export class AddressPipe implements PipeTransform {

  constructor() { }

  transform(stateInfo: string, states: State[]): City[] {
    if (!states){
      return []
    }
    for (let state of states) {
      if (state.id == stateInfo || state.code == stateInfo) {
        return state.cities;
      }
    }
    return [];
  }

}
