import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'findPropertyByKey'
})
export class FindPropertyByKeyPipe implements PipeTransform {

  /**
   * @param objectList The list to be filtered
   * @param keyName Name of the key to be filtered @example 'id'
   * @param keyValue Value of the key name property
   * @param propertyName The propert to be returned @example 'description'
   */
  transform<T>(objectList: Array<T>, keyName: keyof T, keyValue: T[keyof T], propertyName?: keyof T): any {
    if(!objectList){
      return
    }
    const el = objectList.find(obj => obj[keyName] && obj[keyName] === keyValue);

    if(el){
      return (propertyName ? el[propertyName] : el);
    }
    return null;
  }

}
