export const cashTypes = {
  rent: { id: 1, label: 'ELEMENT.CASH_TYPE.RENT' },
  phone: { id: 2, label: 'ELEMENT.CASH_TYPE.PHONE' },
  internet: { id: 3, label: 'ELEMENT.CASH_TYPE.INTERNET' },
  water: { id: 4, label: 'ELEMENT.CASH_TYPE.WATER' },
  electricity: { id: 5, label: 'ELEMENT.CASH_TYPE.ELECTRICITY' },
  supplier: { id: 6, label: 'ELEMENT.CASH_TYPE.SUPPLIER' },
  expense: { id: 7, label: 'ELEMENT.CASH_TYPE.EXPENSE' },
  ro: { id: 8, label: 'ELEMENT.CASH_TYPE.RO' },
  income: { id: 9, label: 'ELEMENT.CASH_TYPE.INCOME' }
}
