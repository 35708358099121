import { Pipe, PipeTransform } from '@angular/core';
import { PAYMENT_METHODS } from '../lists/payment-methods';

export type PAYMENT_APPLICATION = "cashier" | "bank" | "cardAdmin" | "noPayment";

@Pipe({
  name: 'paymentApplication'
})
export class PaymentApplicationPipe implements PipeTransform {

  /**
   * 
   * @param form The PAYMENT_METHOD used to pay or receive
   * @param isPay Used to know if a debit payment should move bank balance or create a title against the cardAdmin.
   * Don't worries, if you just want know if the application move cashier, you can pass any boolean value without problems;
   */
  transform(form: PAYMENT_METHODS, isPay: boolean): PAYMENT_APPLICATION {
    switch (form) {
      case PAYMENT_METHODS.money:
        return "cashier";
      case PAYMENT_METHODS.food:
      case PAYMENT_METHODS.fuel:
      case PAYMENT_METHODS.fastFood:
      case PAYMENT_METHODS.bankSlip:
      case PAYMENT_METHODS.dynamicPix:
      case PAYMENT_METHODS.staticPix:
      case PAYMENT_METHODS.bankTransfer:
      case PAYMENT_METHODS.check:
      case PAYMENT_METHODS.deposit:
      case PAYMENT_METHODS.duplicate:
        return "bank";
      case PAYMENT_METHODS.debit:
        if(isPay){
          return "bank";
        } else {
          return "cardAdmin"
        }
      case PAYMENT_METHODS.credit:
        return "cardAdmin"
      default:
        return "noPayment";
    }
  }

}
