import { Pipe, PipeTransform } from '@angular/core';
import { Labor } from '../../interface/labor';

const RESULTS_SIZE = 30;

@Pipe({
  name: 'laborFilter'
})
export class LaborFilterPipe implements PipeTransform {

  transform(labors: Labor[], text = '', property = ''): Labor[] {
    if (!labors) {
      return [];
    }
    if(!text){
      return labors.slice(0, RESULTS_SIZE);
    }
    text = text.toLowerCase();
    if (property) {
      const filtered: Labor[] = [];
      for (let labor of labors) {
        if (labor[property].toLowerCase().includes(text)) {
          filtered.push(labor);
          if (filtered.length == RESULTS_SIZE) {
            break;
          }
        }
      }
      return filtered;
    }

    const filtered: Labor[] = [];
    for (let labor of labors) {
      if (labor.description.toLowerCase().includes(text) || (labor.code && labor.code.toString().toLowerCase().includes(text))) {
        filtered.push(labor);
        if (filtered.length == RESULTS_SIZE) {
          break;
        }
      }
    }
    return filtered;
  }

}
