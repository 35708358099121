import { Pipe, PipeTransform } from '@angular/core';
import { languages } from '../lists/languages';

@Pipe({
  name: 'language'
})
export class LanguagePipe implements PipeTransform {

  transform(value: number, property = 'label'): any {
    for (let language of Object.keys(languages)) {
      if (languages[language].id == value) {
        return languages[language][property];
      }
    }
    return '';
  }

}
