import { Pipe, PipeTransform } from '@angular/core';
import { RO_STATES } from '../lists/ro-states';

export type RoStateGroup ="budget" | "opened" | "closed" | "ended" | "rejected" | "started" ;
export type RoListStateGroup = RoStateGroup | "all" | "integrated_opened"

@Pipe({
  name: 'roStateGroup'
})
export class RoStateGroupPipe implements PipeTransform {

  transform(value: number, withStarted = false): RoStateGroup {
    switch (value) {
      case RO_STATES.budget: // 2
      case RO_STATES.authorization: // 4
      case RO_STATES.appointment: // 5
      case RO_STATES.readyToStart: // 6
        return 'budget';

      case RO_STATES.started: // 7
        return 'opened';

      case RO_STATES.toReceive: // 8
      case RO_STATES.evaluation: // 9
        return 'closed';

      case RO_STATES.finished: // 10
        return 'ended';

      case RO_STATES.rejected: // 11
        return 'rejected';
      default:
        return "ended";
    }

  }

}
