import { Pipe, PipeTransform } from '@angular/core';
import { IMPORTER_STATUS } from '../lists/importer-status';

@Pipe({
  name: 'importerStatus'
})
export class ImporterStatusPipe implements PipeTransform {

  transform(value: number): string {
    const resp = IMPORTER_STATUS[value];
    return resp || ""
  }

}
