import { Pipe, PipeTransform } from '@angular/core';
import { PartGroup } from '../../interface/part-group';

@Pipe({
  name: 'groupFilter'
})
export class GroupFilterPipe implements PipeTransform {

  transform(groups: PartGroup[], text = '', property = ''): PartGroup[] {
    if (!groups) {
      return [];
    }
    text = text.toLowerCase();
    if (property) {
      const filtered: PartGroup[] = [];
      for (let group of groups) {
        if (group[property].toLowerCase().includes(text)) {
          filtered.push(group);
          if (filtered.length == 100) {
            break;
          }
        }
      }
      return filtered;
    }
    return groups.filter(item =>
      item.description.toLowerCase().includes(text) || item.code.toLowerCase().includes(text)
    );
  }

}
