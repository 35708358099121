import { Pipe, PipeTransform } from '@angular/core';
import { Operation } from 'src/app/interface/operation';

@Pipe({
  name: 'operationTitle'
})
export class OperationTitlePipe implements PipeTransform {

  transform(operation: Operation): string {
    if(!operation){
      return "";
    }
    let operationDescription = `Operação de: ${operation.entry ? 'ENTRADA' : 'SAÍDA'}\n`;
    operationDescription += operation.generateFinancial ? `Gera financeiro\n` : `Não gerra financeiro\n`;
    operationDescription += operation.changeStock ? `Movimenta estoque\n` : `Não movimenta estoque\n`;
    operationDescription += `Documento ${operation.docFiscal ? 'FISCAL' : 'GERENCIAL'} de peças\n`;
    operationDescription += `Documento ${operation.docFiscalServico ? 'FISCAL' : 'GERENCIAL'} de serviços\n`;
    if(operation.operationFunction){
      operationDescription += `Função: ${operation.operationFunction || ""}\n`;
    }
    return operationDescription;
  }

}
