import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'min'
})
export class MinPipe implements PipeTransform {

  transform(value: number, compareValue: number, allowNegatives?: boolean): any {
    if (!value || !compareValue) {
      return 0;
    }

    if (!allowNegatives && (value < 0 || compareValue < 0)) {
      return 0;
    }

    return Math.min(value, compareValue);
  }

}
