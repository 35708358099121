import { Pipe, PipeTransform } from '@angular/core';

/**
 * Try to define the mimeType of a provided url
 */
@Pipe({
  name: 'mediaType'
})
export class MediaTypePipe implements PipeTransform {

  transform(url: string, defaultType = ''): string {
    if(url.includes('image')) return 'image.jpg'
    if(url.includes('jpeg')) return 'image/jpeg';
    if(url.includes('jpg')) return 'image/jpg';
    if(url.includes('png')) return 'image/png';

    if(url.includes('audio')) return 'audio/mp3';
    if(url.includes('mp3')) return 'audio/mp3';
    if(url.includes('aac')) return 'audio/aac';
    if(url.includes('ogg')) return 'audio/ogg';

    if(url.includes('video')) return 'video/mp4';
    if(url.includes('JPEG')) return 'video/JPEG';
    if(url.includes('mp4')) return 'video/mp4';
    return defaultType;
  }

}
