import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ExpenseType } from '../interface/expense-type';
import { first, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { firstValueFrom } from 'rxjs';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root'
})
export class ExpenseService {

  private _isFromMatrix = true;


  constructor(
    private http: HttpClient,
    private dataService: DataService
  ) { }

  async create(expense: ExpenseType): Promise<string> {
    const url = `${environment.mkgoURL}/api/v1/expenseType`;
    const header = await firstValueFrom(this.dataService.httpOptions(this._isFromMatrix));
    const resp: any = await this.http.post(url, expense, header).pipe(first()).toPromise()
    return resp["id"];
  }

  async update(expense: ExpenseType) {
    const url = `${environment.mkgoURL}/api/v1/expenseType/${expense._id}`;
    const header = await firstValueFrom(this.dataService.httpOptions(this._isFromMatrix));
    const resp = await this.http.put(url, expense, header).pipe(first()).toPromise();
    return resp
  }

  async getById(id: string): Promise<ExpenseType> {
    const url = `${environment.mkgoURL}/api/v1/expenseType/${id}`;
    const header = await firstValueFrom(this.dataService.httpOptions(this._isFromMatrix));
    return firstValueFrom(
      this.http.get<ExpenseType>(url, header).pipe(
        map(exp => {
          if (exp.dre === undefined) {
            exp.dre = true; // by default, show expenses on DRE
          } else {
            exp.dre = Boolean(exp.dre);
          }
          return exp;
        })
      ));
  }

  async getAll(cnpj?: string): Promise<ExpenseType[]> {
    const url = `${environment.mkgoURL}/api/v1/expenseType`;
    const header = await firstValueFrom(this.dataService.httpOptions(cnpj || this._isFromMatrix));
    const expenses = await firstValueFrom(
      this.http.get<{ expenseType: ExpenseType[] }>(url, header).pipe(
        map(resp => {
          for (const exp of resp.expenseType) {
            if (exp.dre === undefined) {
              exp.dre = true // by default, show expenses on DRE
            } else {
              Boolean(exp.dre);
            }
          }
          return resp.expenseType;
        })
      ));
    return expenses;
  }

  async delete(id: string) {
    const url = `${environment.mkgoURL}/api/v1/expenseType/${id}`;
    const header = await firstValueFrom(this.dataService.httpOptions(this._isFromMatrix));
    const resp: any = await this.http.delete(url, header).pipe(first()).toPromise()
    return resp
  }


}
