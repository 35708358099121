import { Pipe, PipeTransform } from '@angular/core';
import { Client } from '../../interface/client';
import { Utilities } from '../../class/utilities';
import { ClientUtilities } from '../../class/client-utilities'

@Pipe({
  name: 'clientFilter'
})
export class ClientFilterPipe implements PipeTransform {

  transform(clients: Client[], text = '', property = ''): Client[] {
    if (!clients || !text) {
      return [];
    }
    text = text.toLowerCase();
    if (property) {
      const filtered: Client[] = [];
      const splitedClient: Client = (property == 'nameDocument') ? ClientUtilities.splitNameDocument(text) : { document: '' };
      for (let client of clients) {
        if (property == 'nameDocument') {
          if ((client.name.toLowerCase().includes(splitedClient.name) && splitedClient.name != '')
            || (ClientUtilities.removeMask(client.document).includes(splitedClient.document)) && splitedClient.document != ''
            || (splitedClient.name == '' && splitedClient.document == '')) {
            filtered.push(client);
            if (filtered.length == 100) {
              break;
            }
          }
        } else if(property === 'document'){
          if (ClientUtilities.removeMask(client.document).includes(ClientUtilities.removeMask(text))){
            filtered.push(client)
            if (filtered.length == 100) {
              break;
            }
          }
        } else {
          if (client[property].toLowerCase().includes(text)) {
            filtered.push(client);
            if (filtered.length == 100) {
              break;
            }
          }
        }
      }
      return filtered;
    }
    const digits = Utilities.removeMask(text);
    return clients.filter(client =>
      client.name.toLowerCase().includes(text)
      || (digits && (client.document.includes(digits) || client.phone1.includes(digits)))
    );
  }
}

