import { Pipe, PipeTransform } from '@angular/core';
import { partStates } from '../lists/part-states';

@Pipe({
  name: 'partState'
})
export class PartStatePipe implements PipeTransform {

  transform(value: number, property = 'label'): string {
    for (const partState in partStates) {
      if (partStates[partState].id == value) {
        return partStates[partState][property];
      }
    }
    // new
    return partStates[0]['label']
  }

}
