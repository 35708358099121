import { Pipe, PipeTransform } from '@angular/core';
import { genders } from '../lists/genders';

@Pipe({
  name: 'gender'
})
export class GenderPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    for (let i = 0; i < genders.list.length; i++) {
      if (value == genders.list[i].id) {
        return genders.list[i].label;
      }
    }
  }

}
