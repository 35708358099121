import { Pipe, type PipeTransform } from '@angular/core';

@Pipe({
  name: 'fieldWithOutInfo',
})
export class FieldWithOutInfoPipe implements PipeTransform {

  transform(value: string): string {
    if(value === "NAO CONSTAM INFORMACOES NA BASE CONSULTADA"){
      return "";
    } else {
      return value;
    }
  }

}
