export enum ITEM_MODIFY {
    "not_modified" = 1,
    "modified"
}

export interface ItemResponse {
    "code": string;
    "keyId": string;
    "modify": ITEM_MODIFY.modified | ITEM_MODIFY.not_modified;
    "id"?: string;

    /**
     * The amount representating the not devolutioned items into a original invoice
     */
    "amount": number,
    /** last cost of part when the invoice was created */
    "lastCost"?: number;
    /** average cost of part when the invoice was created */
    "averageCost"?: number;
}
