import { Pipe, PipeTransform } from '@angular/core';
import { RO_STATES } from '../lists/ro-states';

@Pipe({
  name: 'roState'
})
export class RoStatePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    for (let roState of Object.keys(RO_STATES)) {
      if (value == RO_STATES[roState]) {
        return 'ELEMENT.RO_STATE.' + (roState.toUpperCase());
      }
    }
  }

}
