/**
 * State of the invoice
 */
export enum INVOICE_STATE {
    "APPROVED" = 1,
    "REJECTED",
    "GERENCIAL",
    "DEVOLUTIONED",
    "NOT_DEVOLUTIONED",
    "CANCELED",
    "PENDING",
    "GERENCIAL_DEVOLUTIONED",
    "DENIED", /** denegada */
    "USELESS" /** inutilizada */
}