import { Pipe, PipeTransform } from '@angular/core';
import { Utilities } from '../../class/utilities';

@Pipe({
  name: 'valueOfPercentage'
})
export class ValueOfPercentagePipe implements PipeTransform {

  transform(value: number, percentage: number): number {
    return Utilities.calcPercentage(percentage, value);
  }

}
