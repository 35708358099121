export const genders = {
  list: [
    { id: 1, label: 'ELEMENT.GENDER.MAN' },
    { id: 2, label: 'ELEMENT.GENDER.WOMAN' },
    { id: 3, label: 'ELEMENT.GENDER.OTHER' },
    { id: 0, label: 'ELEMENT.GENDER.UNDEFINED' }
  ]
}

export enum gendersEnum {
  undefined,
  male,
  female
}

export type gender = gendersEnum.undefined | gendersEnum.male | gendersEnum.female;

