import { Pipe, PipeTransform } from '@angular/core';
import { Utilities } from '../../class/utilities';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  transform<T>(array: Array<T>, searchString: string, property: string | string[]): Array<T> {
    if (!array || !property) {
      return []
    }

    if (typeof searchString !== "string") {
      searchString = `${searchString}`;
    }

    if (!searchString) {
      return array
    }

    const match = Utilities.removeDiacritics(Utilities.removeCharacters(searchString)).toLocaleLowerCase();

    let filtered = array;
    if (typeof property === 'string') {
      filtered = array.filter(item => {
        return item[property] !== undefined && Utilities.removeDiacritics(Utilities.removeCharacters(`${item[property]}`)).toLowerCase().includes(match);
      });
    } else {
      filtered = array.filter(item => {
        return property.some(p => item[p] !== undefined && Utilities.removeDiacritics(Utilities.removeCharacters(`${item[p]}`)).toLowerCase().includes(match))
      })
    }
    return filtered

  }

}
