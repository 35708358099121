import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'filterAutocomplete'
})
export class FilterAutocompletePipe implements PipeTransform {


  transform(array: any[], searchString: string, property: string): any {

    if (!searchString) {
      return array;
    }

    let filtered: string[] = [];
    let item;
    for (let i = 0; i <array.length; i++) {
      item = array[i];

      if ((new String(item[property]).toLowerCase().indexOf(searchString.toLowerCase()))!==-1) {
        if (filtered.indexOf(item) === -1)
          filtered.push(item);
      }
    }
    return filtered

  }

}
