import { Pipe, PipeTransform } from '@angular/core';
import { FormControl } from '@angular/forms';

@Pipe({
  name: 'error'
})
export class ErrorPipe implements PipeTransform {

  transform(errors: FormControl['errors'], ...args: any[]): any {

    if (errors['required']) {
      return 'VALIDATION.REQUIRED_FIELD';
    }

    if (errors['max']) {
      return 'VALIDATION.MAX'
    }

    if (errors['min']) {
      return 'VALIDATION.MIN'
    }

    if (errors['minlength']) {
      return 'VALIDATION.MIN_LENGTH';
    }

    if (errors['maxlength']) {
      return 'VALIDATION.MAX_LENGTH';
    }

    if (errors['duplicated']) {
      return 'VALIDATION.DUPLICATE_VALUE';
    }

    if (errors['matDatepickerMin']) {
      return 'VALIDATION.PAST_DATE';
    }

    if (errors['past']) {
      return 'VALIDATION.PAST_TIME';
    }

    if (errors['notFound']) {
      return 'VALIDATION.NOT_FOUND';
    }

    if (errors['internal']) {
      return 'VALIDATION.INTERNAL';
    }

    if (errors['email']) {
      return 'VALIDATION.INVALID_EMAIL';
    }

    if (errors['digit']) {
      return 'VALIDATION.INVALID_DIGIT';
    }

    if (errors['equalDigits']) {
      return 'VALIDATION.EQUAL_DIGITS';
    }

    if (errors["equal"]) {
      return 'VALIDATION.EQUAL.' + errors['equal'];
    }

    if (errors['inexistent']) {
      return 'VALIDATION.CNPJ_NOT_REGISTERED';
    }

    if (errors['sent']) {
      return 'VALIDATION.ALREADY_SENT';
    }

    if (errors["min-date"]) {
      return 'VALIDATION.DATE-MINIMUM'
    }
    if (errors["max-date"]) {
      return 'VALIDATION.DATE-MAXIMUM'
    }

    if (errors["internalServerError"]) {
      return 'ELEMENT.ERROR.internalServerError'
    }

    if (errors["invalidId"]) {
      return 'ELEMENT.ERROR.invalidId'
    }

    if (errors["duplicatedValue"]) {
      return 'ELEMENT.ERROR.duplicatedValue'
    }

    if (errors["fieldsRequired"]) {
      return 'ELMENT.ERROR.fieldsRequired'
    }

    if (errors["notFound"]) {
      return 'ELEMENT.ERROR.notFound'
    }

    if (errors["userNotFound"]) {
      return 'ELEMENT.ERROR.userNotFound'
    }

    if (errors["companyNotFound"]) {
      return 'ELEMENT.ERROR.companyNotFound'
    }

    if (errors["ticketNotFound"]) {
      return 'ELEMENT.ERROR.ticketNotFound'
    }

    if (errors["companyBlocked"]) {
      return 'ELEMENT.ERROR.companyBlocked'
    }

    if (errors["personNotFound"]) {
      return 'ELEMENT.ERROR.personNotFound'
    }

    if (errors["vehicleNotFound"]) {
      return 'ELEMENT.ERROR.vehicleNotFound'
    }

    if (errors["roNotFound"]) {
      return 'ELEMENT.ERROR.roNotFound'
    }

    if (errors["checklistNotFound"]) {
      return 'ELEMENT.ERROR.checklistNotFound'
    }

    if (errors["invalidCompany"]) {
      return 'ELEMENT.ERROR.invalidCompany'
    }

    if (errors["invalidPermissions"]) {
      return 'ELEMENT.ERROR.invalidPermissions'
    }

    if (errors["invalidState"]) {
      return 'ELEMENT.ERROR.invalidState'
    }

    if (errors["invalidValue"]) {
      return 'ELEMENT.ERROR.invalidValue'
    }

    if (errors["invalidDateFormat"]) {
      return 'ELEMENT.ERROR.invalidDateFormat'
    }

    if (errors["invalidBreakdowns"]) {
      return 'ELEMENT.ERROR.invalidBreakdowns'
    }

    if (errors["invalidParameters"]) {
      return 'ELEMENT.ERROR.invalidParameters'
    }

    if (errors["biddingNotFound"]) {
      return 'ELEMENT.ERROR.biddingNotFound'
    }

    if (errors["processNotFound"]) {
      return 'ELEMENT.ERROR.processNotFound'
    }
    if (errors["sumNoMatch"]) {
      return 'ELEMENT.ERROR.sumNoMatch'
    }
    if (errors['phoneNotUnique']) {
      return 'VALIDATION.DUPLICATE_VALUE'
    }
    return 'VALIDATION.INVALID_VALUE';

  }

}
