import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'indexIn'
})
export class IndexInPipe implements PipeTransform {

  /** Return the index of a element in array */
  transform(element: any, elementArray: any[]): number {
    if (!elementArray || !elementArray.length) {
      return -2;
    }
    return elementArray.indexOf(element);
  }

}
