import { Pipe, PipeTransform } from '@angular/core';
import { NFSE_item } from '../../interface/nfse-item';
import { Labor } from '../../interface/labor';

@Pipe({
  name: 'invoiceLaborIncluded'
})
export class InvoiceLaborIncludedPipe implements PipeTransform {

  transform(selectedItems: NFSE_item[], labor: Labor): boolean {
    if (!labor || !selectedItems || !selectedItems.length) {
      return false
    }

    return selectedItems.some(item => item.ItemCod === labor.code)
  }

}
