import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { first } from 'rxjs/operators';
import { TitleType } from '../interface/title-type';
import { environment } from '../../environments/environment';
import { firstValueFrom } from 'rxjs';
import { DataService } from './data.service';
import { ObjectId } from '../shared/type-aliases/object-id';

interface apiTitleType {
  _id?: string,
  code?: any,
  description: any,
  payReceive: any,
  "type"?: ObjectId
}

@Injectable({
  providedIn: 'root'
})
export class TitleTypeService {
  private _isFromMatrix = true;


  private _titleTypes: TitleType[]

  constructor(
    private http: HttpClient,
    private _dataService: DataService
  ) {
    // this.setTitleTypes()
  }

  // private async setTitleTypes() {
  //   const titleTypes = await this.getAll();
  //   this.titleTypes = titleTypes;
  // }

  set titleTypes(titleTypes: TitleType[]) {
    this._titleTypes = titleTypes;
  }

  get titleTypes() {
    return this._titleTypes
  }

  public async getAll(cnpj?: string): Promise<TitleType[]> {
    const url = `${environment.mkgoURL}/api/v1/title-type`;
    const header = await firstValueFrom(this._dataService.httpOptions(cnpj || this._isFromMatrix));
    const resp = await firstValueFrom(this.http.get<{ titleTypes: apiTitleType[] }>(url, header));
    const titles = resp.titleTypes.map(t => this.complyApp(t));
    return titles
  }

  /**
   * @todo NOT WORKING
   */
  public async getById(id: string): Promise<TitleType> {
    const url = `${environment.mkgoURL}/api/v1/title-type/${id}`;
    const header = await firstValueFrom(this._dataService.httpOptions(this._isFromMatrix));
    const resp: any = await this.http.get(url, header).pipe(first()).toPromise()
    return this.complyApp(resp)
  }

  public async register(titleType: TitleType): Promise<string> {
    const url = `${environment.mkgoURL}/api/v1/title-type`;
    const apiObject = JSON.stringify(this.complyAPI(titleType));
    const header = await firstValueFrom(this._dataService.httpOptions(this._isFromMatrix));
    const resp: any = await this.http.post(url, apiObject, header).pipe(first()).toPromise()
    return resp['id']
  }

  public async update(titleType: TitleType): Promise<string> {
    const url = `${environment.mkgoURL}/api/v1/title-type/${titleType._id}`;
    const apiObject = JSON.stringify(this.complyAPI(titleType));
    const header = await firstValueFrom(this._dataService.httpOptions(this._isFromMatrix));
    const resp: any = await this.http.put(url, apiObject, header).pipe(first()).toPromise()
    return resp['id']
  }


  /** 
   * @ignore
   * Not implemented into API 
   */
  public async delete(titleTypeId: string) {
    const url = `${environment.mkgoURL}/api/v1/title-type/${titleTypeId}`;
    const header = await firstValueFrom(this._dataService.httpOptions(this._isFromMatrix));
    const resp: any = await this.http.delete(url, header).pipe(first()).toPromise()
    return resp;
  }


  private complyAPI(titleType: TitleType): apiTitleType {
    return <apiTitleType>{
      description: titleType.description,
      payReceive: titleType.isPay,
      type: titleType.typeForAdvance ?? null
    }
  }

  private complyApp(apiTitle: apiTitleType): TitleType {
    return {
      _id: apiTitle._id,
      code: apiTitle.code,
      description: apiTitle.description,
      isPay: apiTitle.payReceive,
      typeForAdvance: apiTitle.type
    }
  }

}
