import { Pipe, PipeTransform } from '@angular/core';
import { NF_response } from '../../interface/NF-response';
import { ITEM_MODIFY } from '../../interface/item-response';

@Pipe({
  name: 'invoiceDevolutioned'
})
export class InvoiceDevolutionedPipe implements PipeTransform {

  /**
   * @returns true, if all or some invoice items already has been devolutioned 
   * 
   * Be sure the invoice have items
  */
  transform(invoice: NF_response): boolean {
    if(!invoice.items || !invoice.items.length){
      return false
    }
    return invoice.items.some(item => item.modify === ITEM_MODIFY.modified || item.amount === 0);
  }

}
