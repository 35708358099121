import { Pipe, PipeTransform } from '@angular/core';
import { Part } from '../../interface/part';

@Pipe({
  name: 'partInfo'
})
export class PartInfoPipe implements PipeTransform {

  transform(part: Part, brandList: Map<string, string>, saleValue: string): string {
    let message = ""
    if (part.brand) {
      message += `MARCA: ${brandList.get(part.brand)}\n`;
    }
    if (part.code) {
      message += `CÓDIGO: ${part.code}\n`;
    }
    message += `ESTOQUE DISPONÍVEL: ${part.availableStock ? part.availableStock : '0'}\n`;
    if (part.saleValue) {
      /** @todo */
      // const saleValue = currencyPipe.transform(part.saleValue);
       part.saleValue;message += `VALOR DE VENDA: ${saleValue}\n`;
    }
    return message;
  }

}
