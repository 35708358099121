import { Pipe, PipeTransform } from '@angular/core';
import { ITEM_MODIFY, ItemResponse } from '../../interface/item-response';

@Pipe({
  name: 'itemModified'
})
export class ItemModifiedPipe implements PipeTransform {

  transform(code: string, responseItems: ItemResponse[]): boolean {
    const item = responseItems.find(rItem => rItem.code === code);
    if(!item){
      return false
    }
    return item.modify === ITEM_MODIFY.modified;
  }

}
