import { Pipe, PipeTransform } from '@angular/core';
import { laborStates } from '../lists/labor-states';

@Pipe({
  name: 'laborState'
})
export class LaborStatePipe implements PipeTransform {

  transform(value: number, property = 'label'): string {
    for (const laborState in laborStates) {
      if (laborStates[laborState].id == value) {
        return laborStates[laborState][property];
      }
    }
    // new
    return laborStates[0][property]
  }

}
