import { Pipe, PipeTransform } from '@angular/core';
import { RoLaborFull } from '../../interface/ro-labor-full';
import { Labor } from '../../interface/labor';

@Pipe({
  name: 'roLaborIncluded'
})
export class RoLaborIncludedPipe implements PipeTransform {

  transform(roLabors: RoLaborFull[], labor: Labor): boolean {
    if (!labor || !roLabors || !roLabors.length) {
      return false
    }

    return roLabors.some(roLabor => roLabor.labor && roLabor.labor.id === labor.id)

  }

}
