import { Pipe, PipeTransform } from '@angular/core';
import { PAYMENT_METHODS } from '../lists/payment-methods';


@Pipe({
  name: 'paymentMethod'
})
export class PaymentMethodPipe implements PipeTransform {

  paymentKeys = Object.keys(PAYMENT_METHODS);

  transform(value: string): string {
    if(PAYMENT_METHODS[value]){
      return PAYMENT_METHODS[value]
    }

    for (const key of this.paymentKeys) {
      if(PAYMENT_METHODS[key] === value){
        return key
      }
    }
    // new
    return '99'
  }

}
