import { Pipe, PipeTransform } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Pipe({
  name: 'errorsOnFields'
})
export class ErrorsOnFieldsPipe implements PipeTransform {

  transform(form: FormGroup, fields: string[]): any {
    if(!form || !fields){
      return true
    }
    return (fields.some(name => {
      const control = form.get(name);
      if(!control){
        return false
      }
      return Boolean(control.errors)
    }))
  }

}
