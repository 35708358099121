import { Pipe, PipeTransform } from '@angular/core';
import { maritalStatus } from '../lists/marital-status';

@Pipe({
  name: 'maritalStatus'
})
export class MaritalStatusPipe implements PipeTransform {
  default = 'ELEMENT.MARITAL_STATUS.UNDEFINED'
  transform(value: number, args?: any): string {
    for (let i = 0; i < maritalStatus.list.length; i++) {
      if (value == maritalStatus.list[i].id) {
        return maritalStatus.list[i].label;
      }
    }
    return this.default
  }

}
