import { Pipe, PipeTransform } from '@angular/core';

const allDigitsEqualsRegex = /^(.)\1+$/g;
@Pipe({
  name: 'allEquals'
})
export class AllEqualsPipe implements PipeTransform {

  transform(value: string): unknown {
    return Boolean(value?.match(allDigitsEqualsRegex));
  }

}
