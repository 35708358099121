import { Pipe, PipeTransform } from '@angular/core';
import { State } from '../../interface/state';

@Pipe({
  name: 'state'
})
export class StatePipe implements PipeTransform {

  transform(code: string, states: State[]): string {
    if (!code) {
      return '';
    }
    code = code.toLowerCase();
    for (let state of states) {
      if (state.code.toLowerCase() == code) {
        return state.name;
      }
    }
    return '';
  }

}
