import { Pipe, PipeTransform } from '@angular/core';
import { Labor } from '../../interface/labor';

@Pipe({
  name: 'laborInfo'
})
export class LaborInfoPipe implements PipeTransform {

  transform(labor: Labor, saleValue: string): any {
    let message = ""
    if (labor.code) {
      message += `CÓDIGO: ${labor.code}\n`;
    }
    if (labor.LCCode) {
      message += `CÓDIGO LC: ${labor.LCCode}\n`;
    }
    if(labor.KmToChange){
      message += `KM PARA TROCA: ${labor.KmToChange}\n`;
    }
    if(labor.monthToChange){
      message += `MESES PARA TROCA: ${labor.monthToChange}\n`;
    }
    if (labor.saleValue) {
      /** @todo */
      // const saleValue = new CurrencyPipe('BRL').transform(labor.saleValue);
      message += `VALOR DE VENDA: ${saleValue}\n`;
    }
    return message;
  }

}
