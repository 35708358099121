import { Pipe, type PipeTransform } from '@angular/core';

@Pipe({
  name: 'osCilindradasCalculate',
})
export class OsCilindradasCalculatePipe implements PipeTransform {

  transform(value: string): any {
    Number(value);
    const numValue = parseFloat(value);
    if (isNaN(numValue)) {
      return '';
    }

    let result: string;

    if (numValue > 960) {
      const roundedValue = Math.round(numValue / 100) * 100;
      result = roundedValue.toString();
    } else {
      return '';
    }

    
    if (result.length > 3) {
      if (result.endsWith('00')) {
        result = result.slice(0, -2);
      }
      let oneNumber = result.slice(0, 1);
      let twoNumber = result.slice(1, 2);
      result = '( ' + oneNumber + '.' + twoNumber + ' )';
    }

    result.toString();
    return result;
  }


}
